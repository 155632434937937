import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Rendering Systems`}</h1>
    <p>{`Render manufacturers are constantly striving to improve the quality, durability, appearance and innovation of their products. It’s because of this that we can offer you such a vast selection of high quality finishes and treatments at competitive prices.`}</p>
    <p>{`We have chosen the best manufacturers in the business, bringing their products and our expertise to you in one affordable solution that fits the needs of your property.`}</p>
    <h3><a parentName="h3" {...{
        "href": "/render-suppliers/k-rend/"
      }}>{`K Rend (Kilwaughter Chemical Co Ltd)`}</a></h3>
    <p>{`For over 20 years, K Rend have offered a high quality extensive range of renders and finishes, designed to give a durable weatherproof coating and a natural looking finish. `}<a parentName="p" {...{
        "href": "/render-suppliers/k-rend/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-suppliers/wetherby/"
      }}>{`Wetherby Building Systems Ltd`}</a></h3>
    <p>{`Wetherby Building Systems Ltd (WBS) are a manufacturer and distributor of external wall insulation systems and decorative external renders. `}<a parentName="p" {...{
        "href": "/render-suppliers/wetherby/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-suppliers/weber/"
      }}>{`Weber (Saint-Gobain)`}</a></h3>
    <p>{`Weber are a european mortar manufacturer offering a complete range of solutions developed using advanced formulation technology to meet the highest technical requirements. `}<a parentName="p" {...{
        "href": "/render-suppliers/weber/"
      }}>{`Learn more`}</a></p>
    <h3><a parentName="h3" {...{
        "href": "/render-suppliers/parex/"
      }}>{`Parex Ltd`}</a></h3>
    <p>{`Parex Ltd is a market leader for rendered facade systems offering a wide range of decorative solutions and ideas. In the UK Parex are a rapidly growing company in the rendering market. `}<a parentName="p" {...{
        "href": "/render-suppliers/parex/"
      }}>{`Learn more`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      